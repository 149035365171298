import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../layouts/header';
import Footer from '../layouts/footer';
import FAQdochealth from '../components/faq-health';
const HealthCare = () => {
    return (
        <>
            <Helmet>
                <title>Revolutionize Patient Care with AI Healthcare Chatbot</title>
                <meta name="description" content="Transform patient experiences with our AI Healthcare Chatbot, offering personalized advice and revolutionizing care with cutting-edge technology." />
                <meta name="keywords" content="ai chatbots in healthcare, chatbot in healthcare industry, ai healthcare chatbot, chatbots in healthcare industry, chatbot in healthcare benefits of chatbot in healthcare" />
                <meta name='robots' content='index, follow'/>
                <meta name='googlebot' content='index, follow' />
                <link rel="canonical" href="https://www.kadhaipoma.ai/whatsapp-chatbot-healthcare" />
                <meta name="author" content="Kadhaipoma" />
                <meta property="og:title" content="Revolutionize Patient Care with AI Healthcare Chatbot" />
                <meta property="og:description" content="Transform patient experiences with our AI Healthcare Chatbot, offering personalized advice and revolutionizing care with cutting-edge technology." />
                <meta property="og:image" content="chatbot solution for healthcare industry" />
                <meta property="og:url" content="https://www.kadhaipoma.ai/whatsapp-chatbot-healthcare" />
            </Helmet>
            <Header></Header>
            <section className="banner-style-two banner-style-six">
                <div className="pattern-layer" style={{ backgroundImage: "url(assets/images/shape/shape-7.png)" }}></div>
                <div className="auto-container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div className="content-box">
                                <h1>Elevate Your Healthcare Experience with AI Healthcare Chatbot</h1>
                                <p>Easily ensure emergency assistance, schedule appointments more efficiently, and support patients better with a chatbot for healthcare. Your health bot is always available for maximum effectiveness. Start now to experience the healthcare interaction of the future!</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                            <div className="image-box pl_190">
                                <figure className="image"><img src="assets/images/healthcare/ai-healthcare-chatbot.png" alt="" /></figure>
                                <div className="message-box">
                                    <div className="message">
                                        <p>Hello! How can we assist you today?</p>
                                        <span>08:15 AM</span>
                                    </div>
                                    <div className="message reply-message">
                                        <p>Could you please provide the clinic <br />timings for the weekend?</p>
                                    </div>
                                    <div className="message">
                                        <p>Certainly! We are open from 9 AM to 12 PM.</p>
                                        <span>Just Now</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
           
            <section className="feature-section pt_60 pb_60 white-bg">
                <div className="auto-container">
                    <div className="sec-title light centred mb_50">
                        <h2>Why is the automation of WhatsApp Chatbots necessary for your Healthcare service?</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                            <div className="feature-block-one">
                                <div className="inner-box">
                                    <div className="icon-box"><img src='/assets/images/healthcare/icons/enhancepatientengagement-01.svg' /></div>
                                    <h3>Enhance Patient Engagement</h3>
                                    <p>To improve healthcare, establish a real-time connection with patients, and provide prompt assistance and personalized interactions.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                            <div className="feature-block-one">
                                <div className="inner-box">
                                    <div className="icon-box"><img src='/assets/images/healthcare/icons/savetimeandeffort-01.svg' /></div>
                                    <h3>Save Time and Effort</h3>
                                    <p>Automate repetitive processes like making appointments, renewing prescriptions, answering health questions, and updating systems.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                            <div className="feature-block-one">
                                <div className="inner-box">
                                    <div className="icon-box"><img src='/assets/images/healthcare/icons/enhancehealthcareservices-01.svg' /></div>
                                    <h3>Enhance Healthcare Services</h3>
                                    <p>Increase overall efficiency by using intelligent WhatsApp automated chatbots to help patients navigate healthcare procedures.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                            <div className="feature-block-one">
                                <div className="inner-box">
                                    <div className="icon-box"><img src='/assets/images/healthcare/icons/improvepatientexperience-01.svg' /></div>
                                    <h3>Improve Patient Experience</h3>
                                    <p>Chatbots provide a smooth, individualized, and responsive healthcare experience to enhance patient satisfaction and well-being.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about-section pb_50">
                <div className="pattern-layer" style={{ backgroundImage: "url(assets/images/shape/shape-3.png)" }}></div>
                <div className="auto-container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                            <div className="image_block_one">
                                <div className="image-box">
                                    <figure className="image"><img src="assets/images/healthcare/improve-patient-experience-healthcare-chatbots.jpg" alt="" /></figure>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div className="content_block_one">
                                <div className="content-box ml_40">
                                    <div className="sec-title light mb_50">
                                        <h2>Improve Patient Experience With AI Healthcare Chatbots</h2>
                                    </div>
                                    <div className="inner-box">
                                        <div className="single-item">
                                            <div className="icon-box"><img className='w32px' src='/assets/images/icons/healthcare/boost-satisfaction.png' /></div>
                                            <h3>Boost Satisfaction</h3>
                                            <p>For better healthcare results, provide timely and efficient patient care using both conventional and cutting-edge healthcare platforms.</p>
                                        </div>
                                        <div className="single-item">
                                            <div className="icon-box"><img className='w32px' src='/assets/images/icons/healthcare/boost-adherence-treatment.png' /></div>
                                            <h3>Boost Adherence to Treatment</h3>
                                            <p>Increase engagement by facilitating seamless interactions that easily turn patients into engaged participants in their healthcare process.</p>
                                        </div>
                                        <div className="single-item">
                                            <div className="icon-box"><img className='w32px' src='/assets/images/icons/healthcare/automate-administration-tasks.png' /></div>
                                            <h3>Automate Administration Tasks</h3>
                                            <p>Implement WhatsApp chatbots to automate tasks such as making appointments and responding to FAQs to ensure operational effectiveness.</p>
                                        </div>
                                        <div className="single-item">
                                            <div className="icon-box"><img className='w32px' src='/assets/images/icons/healthcare/boost-awareness-healthcare.png' /></div>
                                            <h3>Boost Awareness of Healthcare</h3>
                                            <p>Use chatbots in the healthcare industry to increase brand awareness and distribute information about services, building credibility and trust.</p>
                                        </div>
                                        <div className="single-item">
                                            <div className="icon-box"><img className='w32px' src='/assets/images/icons/healthcare/improve-data-insights.png' /></div>
                                            <h3>Improve Your Data Insights</h3>
                                            <p>Interact with patients, review interaction logs, and acquire valuable insight to improve services and make informed decisions for future growth.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="news-style-two pt_60 pb_60">
                <div className="auto-container">
                    <div className="sec-title mb_60 centred">
                        <h2>Why Opt for Kadhaipoma Healthcare Chatbot Builder</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="col-lg-3 col-md-6 col-sm-12 news-block">
                            <div className="news-block-two">
                                <div className="inner-box">
                                    <figure className="image-box"><a href="#"><img src="assets/images/healthcare/chatbot-in-healthcare-industry.jpg" alt="" /></a></figure>
                                    <div className="lower-content">
                                        <h3><a href="#">Customized Healthcare Solutions</a></h3>
                                        <p>With chatbots in the healthcare industry, you can create customized healthcare experiences such as appointment scheduling, data gathering, medication reminders, providing health support, etc.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 news-block">
                            <div className="news-block-two">
                                <div className="inner-box">
                                    <figure className="image-box"><a href="#"><img src="assets/images/healthcare/smooth-patient-interaction.jpg" alt="" /></a></figure>
                                    <div className="lower-content">
                                        <h3><a href="#">Smooth Patient Interaction</a></h3>
                                        <p>Use our healthcare chatbot builder to improve patient engagement by facilitating quick, easy exchanges that lead to better medical results.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 news-block">
                            <div className="news-block-two">
                                <div className="inner-box">
                                    <figure className="image-box"><a href="#"><img src="assets/images/healthcare/ai-chatbot-in-healthcare.jpg" alt="" /></a></figure>
                                    <div className="lower-content">
                                        <h3><a href="#">Advanced Automation Capabilities</a></h3>
                                        <p>By utilizing the advanced automation features of our builder, you can optimize administrative tasks and boost your healthcare processes.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 news-block">
                            <div className="news-block-two">
                                <div className="inner-box">
                                    <figure className="image-box"><a href="#"><img src="assets/images/healthcare/complete-healthcare-support.jpg" alt="" /></a></figure>
                                    <div className="lower-content">
                                        <h3><a href="#">Complete Healthcare Support</a></h3>
                                        <p>Take advantage of our experience integrating chatbots, which ensures strong support catered to the specific needs of the healthcare industry.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="faq-style-two home-2 faq-dark-section mt_30 pb_50 pt_40 gr-bg">
                <div className="pattern-layer"></div>
                <div className="auto-container">
                    <div className="inner-box">
                        <FAQdochealth></FAQdochealth>
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </>
    );
}
export default HealthCare;