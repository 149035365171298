import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
const PageNotFound = () => {
    return (
        <>
            <Helmet>
                <title>Pagenot Found | Whatsapp Chatbot API | Whatsapp business chatbot | Kadhaipoma</title>
                <meta name="description" content="" />
                <meta name="keywords" content="react, meta tags, seo" />
                <meta name="author" content="Kadhaipoma" />
                <meta property="og:title" content="Pagenot Found | Whatsapp Chatbot API | Whatsapp business chatbot | Kadhaipoma" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="" />
                <meta property="og:url" content="" />
                <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"/>
                <meta name='googlebot' content='index, follow' />
            </Helmet>
            <section className="page-title centred">
                <div className="auto-container">
                    <div className="content-box">
                        <h1>Page Not Found</h1>
                    </div>
                </div>
            </section>
            <section className="error-section centred">
                <div className="auto-container">
                    <div className="inner-box">
                        <figure className="error-image"><img src="assets/images/icons/page-not-found.png" alt="" /></figure>
                        <h2>Oops! That Page Can Not be Found.</h2>
                        <div className="btn-box">
                            <Link to="/home" className="theme-btn btn-one"><span>Back to Home</span></Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default PageNotFound;