import { colors } from '@mui/material';
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
const ThanksMsg = () => {

    return (
        <>
            <section className="page-title centred">
                <div className="auto-container">
                    <div className="content-box">
                        <h1>Thank You</h1>
                        <ul className="bread-crumb clearfix">
                            <li><Link to="/home">Back to Home</Link></li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="contact-info-section mb_60 mt_60">
                <div className="auto-container">
                    <div className='confirm-msg-body'>
                        <div className='confirm-title'>
                            <div className='confirm-email-img mb_20'><img src="assets/images/icons/email-confirm.png" alt="" /></div>
                        </div>
                        <p className='align-center'>Thank you for choosing Kadhaipoma! Our team will promptly send you further details to ensure a smooth and successful experience.</p>
                    </div>
                </div>
            </section>
        </>
    );
}
export default ThanksMsg;