import { useState } from 'react'
import { Link } from 'react-router-dom'
import './mobile.css'
import MUIModal from './modal'

const MobileNavbar = () => {
    const [showNavbar, setShowNavbar] = useState(false)

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar)
    }
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <nav className="navbar">
            <MUIModal open={open} setOpen={setOpen} />
            <div className="container">
                <div className="logo">
                    <a href='https://kadhaipoma.ai'>
                        <img className="logo-width" src="assets/images/kadhaipoma-logo.svg" loading="eager" alt="kadhaipoma-whatsapp-chatbot" />
                        </a>
                </div>
                <div className="menu-icon" onClick={handleShowNavbar}>
                    <img className="hamburger" src="assets/images/menu-burger.svg" alt="" />
                </div>
                <div className={`nav-elements  ${showNavbar && 'active'}`}>
                    <ul className="dropdownmenu">
                        <li className='headnav'><a href='/home'>Home</a></li>
                        <li className='headnav'>
                            <a href="#">Solutions &#9662;</a>
                            <ul className="dropdown">
                                <li><a href='/chatbot-for-ecommerce'>e-Commerce</a></li>
                                <li><a href='/ai-healthcare-chatbot'>Healthcare</a></li>
                                <li><a href='/whatsapp-education-chatbot'>Education</a></li>
                                <li><a href='/finance-chatbot'>Finance</a></li>
                                <li><a href='/travel-chatbot'>Travel</a></li>
                                <li><a href='/chatbot-for-hotels'>Hotel</a></li>
                            </ul>
                        </li>
                        <li className='headnav'><a href="/features">Features</a></li>
                        <li className='headnav'><a href="/about-us">About</a></li>
                        <li className='headnav'><a href="/blog">Blog</a></li>
                        <li className='headnav'><a href="/contact-us">Contact Us</a></li>
                        <li className='headnav sign-uplink'><Link onClick={handleClickOpen} className="theme-btn btn-one"><span>Sign Up Free</span></Link></li>
                    </ul>

                </div>
            </div>
        </nav>
    )
}

export default MobileNavbar