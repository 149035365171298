import * as React from 'react';
import { useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function MUIModal({ open, setOpen }) {
    //   const [open, setOpen] = React.useState(false);

    //   const handleClickOpen = () => {
    //     setOpen(true);
    //   };
    const handleClose = () => {
        setOpen(false);
        setSucessmsg(false);
        setNormalmsg(true);
        setIndustrys('');
        setUsername('');
        setUseremail('');
        setMessage('');
        setUserphone('');
    };

    const [industrys, setIndustrys] = useState();
    const [username, setUsername] = useState('');
    const [useremail, setUseremail] = useState('');
    const [userphone, setUserphone] = useState('');
    const [message, setMessage] = useState('');
    const [sucessmsg, setSucessmsg] = useState(false);
    const [normalmsg, setNormalmsg] = useState(true);
    const [usernameError, setUsernameError] = useState('');
    const [useremailError, setUseremailError] = useState('');
    const [userphoneError, setUserphoneError] = useState('');
    const [industrysError, setIndustrysError] = useState('');
    const [messageError, setMessageError] = useState('');
    const handleSubmit = async (e) => {
        e.preventDefault();
        let isValid = true;

        if (!username || !username.trim()) {
            setUsernameError('Name is required');
            isValid = false;
        } else {
            setUsernameError('');
        }

        if (!useremail || !useremail.trim()) {
            setUseremailError('Email is required');
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(useremail)) {
            setUseremailError('Enter a valid email address');
            isValid = false;
        } else {
            setUseremailError('');
        }

        if (!userphone || !userphone.trim()) {
            setUserphoneError('Phone Number/SkypeID is required');
            isValid = false;
        } else {
            setUserphoneError('');
        }

        if (!industrys || !industrys.trim()) {
            setIndustrysError('Please select an industry');
            isValid = false;
        } else {
            setIndustrysError('');
        }

        if (!message || !message.trim()) {
            setMessageError('Message is required');
            isValid = false;
        } else {
            setMessageError('');
        } if (isValid) {
            try {

                const response = await axios.post("https://console.kadhaipoma.ai/contectbooking", {
                    username,
                    useremail,
                    userphone,
                    industrys,
                    message,
                });


                console.log('Server response:', response.data);
                setSucessmsg(true);
                setNormalmsg(false)
            } catch (error) {
                setSucessmsg(false);
                setNormalmsg(true);
                console.error('Error:', error.response.data);
            }
        }
    };
    return (
        <React.Fragment>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Sign Up
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <section className="contact-section p_20">
                        {normalmsg &&
                            <div className="form-inner">
                                <form method="post" action="">
                                    <div className="row clearfix">
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group ">
                                            <input type="text" name="username" placeholder="Name" required="" value={username} onChange={(e) => setUsername(e.target.value)} />
                                            <span className="error-message">{usernameError}</span>

                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group ">
                                            <input type="email" name="email" placeholder="Email Address" required="" value={useremail} onChange={(e) => setUseremail(e.target.value)} />
                                            <span className="error-message">{useremailError}</span>

                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group ">
                                            <input type="" name="" placeholder="Phone Number/SkypeID" required="" value={userphone} onChange={(e) => setUserphone(e.target.value)} />
                                            <span className="error-message">{userphoneError}</span>

                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group " value={industrys} onChange={(e) => setIndustrys(e.target.value)} >
                                            <select>
                                                <option value={''} >Industry:</option>
                                                <option> Retail and E-Commerce</option>
                                                <option>Healthcare</option>
                                                <option>Finance</option>
                                                <option>Travel and Hospitality</option>
                                                <option>Education</option>
                                                <option>Real Estate</option>
                                                <option>Automotive</option>
                                                <option>Food and Beverage</option>
                                                <option>Insurance</option>
                                                <option>Entertainment</option>
                                                <option>Human Resources</option>
                                                <option>Fitness and Wellness</option>
                                                <option>Legal Services</option>
                                                <option>Marketing and Advertising</option>
                                            </select>
                                            <span className="error-message">{industrysError}</span>

                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group ">
                                            <textarea name="message" placeholder="Type message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                                            <span className="error-message">{messageError}</span>

                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 form-group message-btn mr-0 centred">
                                            <button className="theme-btn btn-one" type="submit" onClick={handleSubmit} name="submit-form"><span>Send Message</span></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        }
                        {sucessmsg && <div className='confirm-msg-body'  >
                            <div className='confirm-title'>
                                <div className='confirm-email-img mb_20'><img src="assets/images/icons/email-confirm.png" alt="" /></div>
                            </div>
                            <p>Thank you for choosing Kadhaipoma! Our team will promptly send you further details to ensure a smooth and successful experience.</p>
                        </div>}
                    </section>
                </DialogContent>

            </BootstrapDialog>
        </React.Fragment>
    );
}