import React, { Component } from 'react';
import Faqhealth from 'react-faq-component';

const data = {
  title: "Frequently Asked Questions about WhatsApp Business API Integration",
  rows: [
    {
      title: "How do healthcare WhatsApp solutions benefit patients and providers?",
      content: "Healthcare WhatsApp solutions enable fast communication, simple appointment scheduling, and 24/7 access to medical support, all of which are beneficial to both patients and doctors. They improve patient involvement and facilitate medical professional administrative tasks. This leads to improved healthcare delivery and patient satisfaction."
    },
    {
      title: "What are some challenges in implementing healthcare chatbot systems?",
      content: "Healthcare chatbot systems may be challenging to implement in terms of maintaining data security and privacy compliance, maintaining the accuracy of the medical information supplied, and facilitating simple integration with existing healthcare systems. To get over these challenges, medical professionals and technology experts must work together through extensive planning, testing, and collaboration."
    },
    {
      title: "What security measures are in place for chatbots in the healthcare industry?",
      content: "Some security measures placed in chatbots in the healthcare industry include encryption of sensitive data, compliance with HIPAA regulations, and regular security audits to identify and address vulnerabilities. Furthermore, access restrictions and authentication procedures ensure that only authorised personnel can interact with patient data, ensuring its confidentiality and integrity."
    },
    {
      title: "Can AI healthcare chatbots provide accurate medical advice?",
      content: "Based on algorithms and medical databases, AI healthcare chatbots can offer comprehensive medical guidance; yet, they shouldn't serve as the place of a qualified medical expert for diagnosis or treatment. They provide generic guidance, symptom assessment, and prescription or appointment reminders; however, seeking the advice of a healthcare provider is crucial for complicated medical problems."
    }]
}
const styles = {
    bgColor: 'false',
    titleTextColor: "#333333",
    titleTextSize: '38px',
    rowTitleColor: "#0D0D0D",
    rowTitleTextSize: '18px',
    rowContentColor: '#6A6A6A',
    rowContentTextSize: '16px',
    rowContentAlign: "left",
    // arrowColor: "red",
};

const config = {
    animate: true,
   // arrowIcon: "V",
    //openOnload: 0,
    //expandIcon: "+",
    //collapseIcon: "-",
};
// export default function MUIModal({ open, setOpen }) {
export default class FAQdochealth extends Component {
  render() {
    return (
      <div>
        <Faqhealth
                data={data}
                styles={styles}
                config={config}
            />
      </div>
    )
  }
}
