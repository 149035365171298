import React, { useEffect } from 'react'
import MUIModal from '../components/modal';
// import ResponsiveNavBar from '../components/navbar';
import MobileNavbar from '../components/mobilenavbar';
// import 'bootstrap/dist/css/bootstrap.css';

const Header = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <MUIModal open={open} setOpen={setOpen} />
      <header className="main-header header-style-one">
      {/* <ResponsiveNavBar></ResponsiveNavBar> */}
        <div className="header-lower">
          <div className="auto-container">
            <MobileNavbar></MobileNavbar>
          </div>
        </div>
      </header>
    </>
  );
}
export default Header;