import React from "react";
import BaseLayouts from './layouts/baselayout';
function App() {
  return (
   <React.Fragment>
    <BaseLayouts></BaseLayouts>
   </React.Fragment>
  );
}

export default App;
