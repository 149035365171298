import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
const Footer = () => {

  return (
    <>
      <section className="cta-style-two">
        <div className="auto-container">
          <div className="inner-container">
            <div className="shape grl-bg"></div>
            <div className="text-box">
              <h2>Transform your business with WhatsApp Chatbot Integration</h2>
              <p>Create your bots using our chatbot platform or let our expert team build them for you. Connect with us today!</p>
            </div>
            <div className="btn-box"> <a href="/contact-us" className="theme-btn btn-one"><span>Book A Free Demo!</span></a> </div>
          </div>
        </div>
      </section>
      <footer className="main-footer style-two">
        <div className="auto-container">
          <div className="widget-section">
            <div className="row clearfix">
              <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                <div className="footer-widget logo-widget">
                  <figure className="footer-logo"><Link to="https://kadhaipoma.ai"><img className="logo-width" src="assets/images/kadhaipoma-footer-logo.svg" loading="eager" alt="kadhaipoma footer logo" /></Link></figure>
                  <p>Kadhaipoma is a WhatsApp chatbot automation platform that provides smooth customer engagement and effective business promotions across multiple channels.</p>
                  <ul className="social-links clearfix">
                    <li><Link target='blank' to="https://www.facebook.com/KadhaipomaAPI"><i className="fa-brands fa-facebook"></i></Link> </li>
                    <li><Link target='blank' to="https://www.instagram.com/kadhaipomaapi/"><i className="fa-brands fa-instagram"></i></Link> </li>
                    <li><Link target='blank' to="https://www.linkedin.com/company/kadhaipoma/?viewAsMember=true"><i className="fa-brands fa-linkedin"></i></Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                <div className="footer-widget links-widget ml_50">
                  <div className="widget-title">
                    <h3>Quick Link</h3>
                  </div>
                  <div className="widget-content">
                    <ul className="links-list clearfix">
                      <li><a href='/about-us'>About Us</a></li>
                      <li><a href='/ecommerce-chatbot'>e-Commerce</a></li>
                      <li><a href='/features'>Features</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                <div className="footer-widget links-widget ml_30">
                  <div className="widget-title">
                    <h3>Useful Links</h3>
                  </div>
                  <div className="widget-content">
                    <ul className="links-list clearfix">
                      <li><a href='/contact-us'>Contact Us</a></li>
                      <li><a href='/terms-condition'>Terms & Condition</a></li>
                      <li><a href='/privacy-policy'>Privacy Policy</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 footer-column">
                <div className="footer-widget contact-widget">
                  <div className="widget-title">
                    <h3>Contact</h3>
                  </div>
                  <div className="widget-content">
                    <ul className="contact-info clearfix">
                      <li><i className="icon-28"></i>No: 7 Narasingapuram, 5th Street, Guindy, Chennai-600 032. </li>
                      <li> <i className="icon-29"></i> <a href="mailto:info@kadhaipoma.ai">info@kadhaipoma.ai</a> </li>
                      <li> <i className="icon-30"></i> <a href="tel:+9092122211">+91-90921 22211</a> </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom centred">
          <div className="auto-container footer-flex">
            <span className='copyright-text'>Copyright &copy; 2024 <Link to="https://kadhaipoma.ai/">kadhaipoma</Link>, All Rights Reserved</span> | <span className='ml_15'>Powered by <Link to="https://heptotechnologies.com" target='_blank'>Hepto</Link></span>
          </div>
        </div>
      </footer>
      <div className="scroll-to-top">
        <div>
          <div className="scroll-top-inner">
            <div className="scroll-bar">
              <div className="bar-inner"></div>
            </div>
            <div className="scroll-bar-text"><i className='fas fa-arrow-left'></i></div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Footer;